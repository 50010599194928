body {
  background-color: aliceblue;
}
.root {
  display:flex;
  flex-wrap: wrap;
  gap:15px;
  margin:15px;
  height:100vh;
}
.content {
  display:flex;
  justify-content: center;
  align-items: center;
  gap:15px;
  margin:15px;
}
.instructions {
display: flex;
flex-direction: column;
}
button {
  font-size: medium;
  padding: 10px;
}

p {
  font-size: medium;
}

td, th {
  padding:10px;
  text-align: center;
}
table, th, td{
  border-collapse: collapse;
  border: 1px solid;
}
ul {
  text-align: left;
}
li {
  margin:10px;
}
